<template>
    <footer class="d-flex flex-wrap py-2 px-4 bg-first">
        <div class="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <a href="https://www.lametropolemobilite.fr/cgv/" target="blank" class="text-white fs-5">
                {{ $t('LegalNotice') }}
            </a>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-center align-items-center my-3 m-md-0">
            <a href="https://www.lametropolemobilite.fr/faq/" target="blank" class="text-white fs-5">
                FAQ
            </a>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <a href="https://ampmetropole.fr/">
                <img :src="require(`@/assets/img/tenants/${ApiKey}/logo-wt.png`)">
            </a>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'FooterComp',
    computed: {
        ...mapGetters(['ApiKey'])
    }
}
</script>

<style lang="scss" scoped>
    footer {
        background-image: url('@/assets/img/tenants/NvlUxQ7ObrtsKbDxQuLRP/background.png');
        background-size: 100%;
        background-position-y: 95%;
        background-repeat: no-repeat;
    }
</style>